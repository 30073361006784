<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState, mapActions } from "vuex";

export default {
  name: "ASRPackagesIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("asrlanguagepack", {
      contents: state => state.asrLanguagePackages
    })
  },

  methods: {
    ...mapActions("asrLanguages", {
      getASRLanguageGroup: "getASRLanguageGroup"
    }),

    initContent() {
      return {
        asr_language_package_name: "",
        transformed_languages: []
      };
    }
  },

  mounted() {
    this.getASRLanguageGroup();
    this.contentType = "Language Package";
    this.primaryKey = "asr_language_package_id";
    this.scope = "sys";
  }
};
</script>

<style lang="scss" scoped></style>
