<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Name") }}</div>
    <div class="details">{{ contentForm.asr_language_package_name }}</div>
    <div v-for="(item, index) in this.detailedLanguages" :key="index">
      <div v-show="item.length && item.length > 0">
        <el-collapse>
          <el-collapse-item :title="getTitle(index, item)">
            <div class="details">
              <el-tag
                :key="language.language_id"
                v-for="language in item"
                style="margin-top:2px;margin-right: 2px;"
              >
                {{ language.language_name + " ||| " + language.language_code }}
              </el-tag>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <el-button
      @click="handleEditBtnClick"
      class="editBtn"
      v-show="this.canWrite()"
      >{{ __("Edit Language Package") }}</el-button
    >
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
    >
      <el-scrollbar ::native="false" style="margin-top: 34px">
        <div style="max-height: calc(100vh - 34px);">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="12" :offset="6">
                <page-header :title="__('Language Package')" :contentId="id" />
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="asr_language_package_name"
                  :label="__('Name')"
                >
                  <el-input
                    v-model="contentForm.asr_language_package_name"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Languages')" prop="languages">
                  <el-cascader
                    v-model="contentForm.transformed_languages"
                    style="width: 100%"
                    :key="cascaderKey"
                    :options="asrLangs"
                    :props="cascader_langs"
                    clearable
                    filterable
                    :collapse-tags="shouldCollapse"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              type="primary"
              @click="submitForm"
              class="submitBtn"
              v-show="this.canWrite()"
              :primaryKey="id"
            />
            <el-button @click="handleCancel" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapGetters, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import _ from "lodash";
import { checkUniqueName } from "@/api/asrlanguagepack";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton
  },
  data() {
    let validateDSName = async (rule, value, callback) => {
      try {
        // update scenario
        if (
          this.id === 0 &&
          value !== this.contentFormInfo.asr_language_package_name
        ) {
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Name already exists")));
          } else {
            callback();
          }
        }
        if (this.id === -1) {
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Name already exists")));
          } else {
            callback();
          }
        }
      } catch (e) {
        console.log(e);
        this.isChecking = false;
      }
    };
    let validateLanguages = async (rule, value, callback) => {
      try {
        if (
          this.contentForm.transformed_languages &&
          this.contentForm.transformed_languages.length &&
          this.contentForm.transformed_languages.length > 0
        ) {
          callback();
        } else {
          callback(new Error(__("Languages are required")));
        }
      } catch (e) {
        console.log(e);
      }
    };
    return {
      validateWrite: false,
      rules: {
        asr_language_package_name: [
          { required: true, trigger: "blur", message: __("Name is required") },
          { validator: validateDSName, trigger: "blur" }
        ],
        languages: [
          {
            required: true,
            trigger: "blur",
            message: __("Languages are required"),
            validator: validateLanguages
          }
        ]
      },
      cascader_langs: {
        multiple: true
      },
      isSubmitting: false,
      isChecking: false
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapState("asrLanguages", {
      asrLangs: state => state.asrLanguages,
      asrLanguageMap: state => state.asrLanguageMap,
      asrLanguageIsLoading: state => state.isLoading
    }),

    ...mapGetters("ttsVoices", {
      getTtsVoiceName: "getTtsVoiceName"
    }),
    cascaderKey() {
      return this.contentFormInfo.id > 0
        ? this.contentFormInfo.id
        : Math.random();
    },
    shouldCollapse() {
      return this.contentForm.transformed_languages.length > 50;
    },
    detailedLanguages() {
      return {
        "Cloud Speech": _.filter(
          this.contentForm.asr_language_package_languages,
          function(item) {
            return (
              item.language_used_in.includes("cloud_speech") &&
              !item.language_provider.includes("deepgram")
            );
          }
        ),
        "Dialogflow ES": _.filter(
          this.contentForm.asr_language_package_languages,
          function(item) {
            return item.language_used_in.includes("dialogflow");
          }
        ),
        "Dialogflow CX": _.filter(
          this.contentForm.asr_language_package_languages,
          function(item) {
            return item.language_used_in.includes("df_cx");
          }
        ),
        Amazon: _.filter(
          this.contentForm.asr_language_package_languages,
          function(item) {
            return item.language_provider.includes("amazon");
          }
        ),
        "Ibm Watson": _.filter(
          this.contentForm.asr_language_package_languages,
          function(item) {
            return item.language_provider.includes("ibm_watson");
          }
        ),
        Lumenvox: _.filter(
          this.contentForm.asr_language_package_languages,
          function(item) {
            return item.language_provider.includes("lumenvox");
          }
        ),

        Deepgram: _.filter(
          this.contentForm.asr_language_package_languages,
          function(item) {
            return (
              item.language_used_in.includes("cloud_speech") &&
              item.language_provider.includes("deepgram")
            );
          }
        )
      };
    }
  },
  methods: {
    ...mapActions("asrlanguagepack", {
      createAsrLanguagePackage: "createAsrLanguagePackage",
      updateAsrLanguagePackage: "updateAsrLanguagePackage"
    }),

    getTitle(index, item) {
      return index + " (" + item.length + ") ";
    },
    //Potentially in future, change this to icon to reduce space.
    getLowerCase(langProvider) {
      return langProvider
        .split("_")
        .join(" ")
        .toLowerCase();
    },
    async addMissingLanguages() {
      let final = [];
      let self = this;
      _.map(self.contentForm.transformed_languages, function(language) {
        let language_item = self.asrLanguageMap[language[1]];
        _.map(language_item, function(item) {
          final.push([item, language[1]]);
        });
      });
      let language_set = new Set(final.map(JSON.stringify));
      self.contentForm.transformed_languages = Array.from(language_set).map(
        JSON.parse
      );
    },

    async submitForm() {
      this.$refs.contentForm.validate(async valid => {
        if (valid) {
          await this.addMissingLanguages();
          this.isSubmitting = true;
          const process =
            this.id === -1
              ? this.createAsrLanguagePackage
              : this.updateAsrLanguagePackage;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;
              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("ASR Language Pack added Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("ASR Language Pack updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              // console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";

.addVoice {
  ::v-deep .el-input__inner {
    background: white;
    text-align: center;
    color: var(--theme-color);
    border: var(--theme-color) 2px solid;
    &::placeholder {
      color: var(--theme-color);
      font-weight: bold;
    }
  }

  ::v-deep .el-input.is-focus {
    border-color: var(--theme-color);
    outline: none;
  }
  ::v-deep .el-input__suffix {
    display: none;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
<style lang="scss">
.editContentItemModal {
  padding: 0;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}
.el-collapse {
  border: none;
}
.el-collapse-item__header {
  border: none;
}
.el-collapse-item__wrap {
  border: none;
}
</style>
